import {
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { AdminDataContext } from "./../../useContext/AdminContext";
import { imgUrl } from "../../url";
import AsmRestaurantDetailsModal from "./AsmRestaurantDetailsModal";

function ASM() {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [openModal, setOpenModal] = useState(false);
  const [owner, setOwner] = useState([]);
  const [data, setData] = useState({});
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [createOwner, setCreateOwner] = useState(false);
  const [restaurantData, setRestaurantData] = useState([]);
  const [type, setType] = useState({
    restaurant: false,
    owner: false,
  });

  const handleOpen = () => {
    setData({});
    setEdit(false);
    setCreateOwner(true);
  };

  const handleOpenModalForRestaurant = (data) => {
    setRestaurantData([]);
    setType({
      restaurant: true,
      owner: false,
    });
    setRestaurantData(data);

    console.log("Owner Restaurant ", data);
    setOpenModal(true);
  };
  const handleOpenModalForOwner = (data) => {
    setRestaurantData([]);
    setType({
      restaurant: false,
      owner: true,
    });
    setRestaurantData(data);

    console.log("Owner Restaurant ", data);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClose = () => setCreateOwner(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,

    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderHeader: () => <strong>{"Name"}</strong>,
    },
    {
      field: "image",
      headerName: "Image",
      width: 120,
      renderHeader: () => <strong>{"Image"}</strong>,
      renderCell: (params) => {
        return (
          <Box
            height={"100%"}
            component="img"
            src={`${imgUrl}/${params.row.photo}`}
            alt=""
          />
        );
      },
    },

    {
      field: "email",
      headerName: "Email",
      width: 270,
      renderHeader: () => <strong>{"Email"}</strong>,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      renderHeader: () => <strong>{"Phone"}</strong>,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 100,
      renderHeader: () => <strong>{"Gender"}</strong>,
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 250,
      renderHeader: () => <strong>{"Designation"}</strong>,
    },
    {
      field: "restaurant",
      headerName: "Restaurant",
      width: 120,
      renderHeader: () => <strong>{"Restaurant"}</strong>,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => handleOpenModalForRestaurant(params.row.restaurants)}
          >
            {params.row.restaurants.length}
          </Button>
        );
      },
    },

    {
      field: "owner",
      headerName: "Owner",
      width: 120,
      renderHeader: () => <strong>{"Owner"}</strong>,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => handleOpenModalForOwner(params.row.ownerData)}
          >
            {params.row.ownerData.length}
          </Button>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderHeader: () => <strong>{"Edit"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              onClick={() => {
                setEdit(true);
                setData({ ...params.row, phone: params.row.phone.slice(-10) });

                setCreateOwner(true);
                // console.log(params.row);
              }}
            >
              Edit
            </Button>
          </>
        );
      },
    },
    {
      field: "block",
      headerName: "Block",
      width: 150,
      renderHeader: () => <strong>{"Block"}</strong>,
      renderCell: (params) => {
        return (
          <>
            <Button
              variant="contained"
              onClick={() => blockAsm(params.row._id)}
            >
              Block
            </Button>
          </>
        );
      },
    },
  ];

  const blockAsm = async (id) => {
    try {
      setLoading(true);
      console.log("id is asm block", id);
      const res = await axios.patch(`/api/v1/admin/block-asm/${id}`);
      snackbar(res.data.message, "success");
      getAllOwner(page, limit, "", "");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOwner(page, limit, "", "");
  }, []);

  // const inputChange = (event) => {
  //   setUpgradeData({ ...upgradeData, [event.target.name]: event.target.value });
  // };

  const getAllOwner = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/asm?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setOwner(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const createOwnerHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let res = "";

      if (edit === true) {
        console.log("edit data is ", data);
        console.log("data._id", data._id);
        res = await axios.patch(`/api/v1/admin/asm?id=${data._id}`, {
          ...data,
          phone: `+91${data.phone}`,
        });
        console.log("edit kro");
        setEdit(false);
      } else {
        res = await axios.post(`/api/v1/admin/asm`, {
          ...data,
          phone: `+91${data.phone}`,
        });
        console.log("create kro");
      }

      snackbar(res.data.message, "success");
      setIsLoading(false);
      handleClose();
      getAllOwner(page, limit, "", "");
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };
  const handleSortModelChange = async (item) => {
    getAllOwner(page, limit, "", "");
    if (item.length === 0) {
      getAllOwner(page, limit, "", "");
    } else {
      getAllOwner(page, limit, item[0].sort, item[0].field);
    }
  };
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    console.log(data);
  };
  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/owners/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setOwner(res.data.owner);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // onClick={createOwnerHandler}
  return (
    <Box>
      <Button onClick={handleOpen} variant="contained">
        Add ASM
      </Button>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={owner}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllOwner(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllOwner(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />

      <AsmRestaurantDetailsModal
        openModal={openModal}
        restaurantData={restaurantData}
        handleCloseModal={handleCloseModal}
        type={type}
      />

      <Modal
        open={createOwner}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item sx={12} component="form" onSubmit={createOwnerHandler}>
              <TextField
                size="small"
                sx={{ mt: 2 }}
                id="outlined-basic"
                fullWidth
                label="name"
                name="name"
                value={data.name}
                required
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                sx={{ mt: 2 }}
                size="small"
                fullWidth
                id="outlined-basic"
                label="email"
                name="email"
                value={data.email}
                required
                variant="outlined"
                onChange={handleChange}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  sx={{ mt: 2 }}
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.gender}
                  required
                  label="Gender"
                  name="gender"
                  onChange={handleChange}
                >
                  {/* "Male", "Female", "Other" */}
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{ mt: 2 }}
                size="small"
                fullWidth
                id="outlined-basic"
                label="phone"
                name="phone"
                type="number"
                required
                value={data.phone}
                variant="outlined"
                onChange={handleChange}
              />
              <Select
                sx={{ mt: 2 }}
                fullWidth
                size="small"
                label="Designation"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.designation}
                name="designation"
                onChange={handleChange}
              >
                <MenuItem value={"Area Sales Manager"}>
                  Area Sales Manager
                </MenuItem>
                <MenuItem value={"Sales Representative"}>
                  Sales Representative
                </MenuItem>
                <MenuItem value={"Business Development Manager"}>
                  Business Development Manager
                </MenuItem>
              </Select>

              {edit === true ? (
                <>
                  <TextField
                    fullWidth
                    sx={{ mt: 2 }}
                    size="small"
                    id="outlined-basic"
                    // sx={{ width: "100%" }}
                    label="Latitude"
                    type="number"
                    onChange={handleChange}
                    name="latitude"
                    value={data.latitude}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    sx={{ mt: 2 }}
                    size="small"
                    id="outlined-basic"
                    // sx={{ width: "100%" }}
                    label="Longitude"
                    onChange={handleChange}
                    name="longitude"
                    type="number"
                    value={data.longitude}
                    variant="outlined"
                  />

                  <Button sx={{ mt: 2 }} variant="contained" type="submit">
                    Update
                  </Button>
                </>
              ) : (
                <Button sx={{ mt: 2 }} variant="contained" type="submit">
                  Create
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}

export default ASM;
