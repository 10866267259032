import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";

import { AdminDataContext } from "./../../useContext/AdminContext";
function FreeDeliveryBoyButAvailFalse() {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [data, setData] = useState([]);

  //   const [page, setPage] = useState(1);
  //   const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "name",
      headerName: "Delivery Boy",
      width: 250,
      renderHeader: () => <strong>{"Delivery Boy"}</strong>,
      renderCell: (params) => params.row.deliveryPartner.name,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderHeader: () => <strong>{"Email"}</strong>,
      renderCell: (params) => params.row.deliveryPartner.email,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 130,
      renderHeader: () => <strong>{"Phone"}</strong>,
      renderCell: (params) => params.row.deliveryPartner.phone,
    },
    {
      field: "available",
      headerName: "available",
      width: 100,
      renderHeader: () => <strong>{"Available"}</strong>,
    },
    {
      field: "online",
      headerName: "online",
      width: 100,
      renderHeader: () => <strong>{"Online"}</strong>,
    },
    {
      field: "availability",
      headerName: "availability",
      width: 200,
      renderHeader: () => <strong>{"Availability"}</strong>,
      renderCell: (params) => {
        const actionSwitchToggle = async (id) => {
          try {
            setLoading(true);
            const response = await axios.patch(
              `/api/v1/admin/delivery/make-available-delivery-partner-true/${id}`
            );

            snackbar(response.data.message, "success");
            freeDeliveryBoyButAvailableFalse();
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return (
          <Button
            type="submit"
            onClick={() => actionSwitchToggle(params.row._id)}
            variant="contained"
          >
            Available True
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    freeDeliveryBoyButAvailableFalse();
  }, []);

  const freeDeliveryBoyButAvailableFalse = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/delivery/free-but-not-available-delivery-partner`
      );
      console.log("delivery", res.data.data);

      setData(res.data.data);
      //   setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Box>
      {/* <Button onClick={addBrand} variant="contained">
        Add brand
      </Button> */}
      <Typography sx={{ fontWeight: 800, my: 1 }}>
        Delivery Boys Available false but free
      </Typography>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={data}
        getRowId={(row) => row._id}
        loading={loading}
        columns={columns}
      />
    </Box>
  );
}

export default FreeDeliveryBoyButAvailFalse;
