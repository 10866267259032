import {
  Box,
  Button,
  TextField,
  Grid,
  Switch,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { AdminDataContext } from "./../useContext/AdminContext";
import { imgUrl } from "../url";
import DragsAndDrop from "../reusableComponent/imageUpload/DragsAndDrop";
import { textFieldStyleForModal } from "./cssStyling/Style";
import ImagePreview from "../reusableComponent/imagePreviewModal/ImagePreview";

function FestiveCategory() {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [category, setCategory] = useState([]);
  const [data, setData] = useState({});
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [createCategory, setCreateCategory] = useState(false);
  // image preview modal
  const [imageUrl, setImageUrl] = useState("");
  const [preview, setPreview] = useState(false);
  const handleOpenPreview = () => setPreview(true);
  const handleClosePreview = () => setPreview(false);
  const handleClickPreview = (img) => {
    setImageUrl(img);
    handleOpenPreview();
  };
  // end image preview modal

  const handleOpen = () => setCreateCategory(true);
  const handleClose = () => {
    setCreateCategory(false);
    setId(null);
    setData({});
  };
  const [id, setId] = useState(null);
  const [image, setImage] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };

  const handleEdit = (row) => {
    console.log("Edit", row);
    setId(row._id);
    setData(row);
    handleOpen();
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 250,
    },
    {
      field: "visible",
      headerName: "Visible",
      width: 120,
      renderCell: (params) => {
        const actionSwitchToggle = async (id, visible) => {
          try {
            setLoading(true);
            const response = await axios.patch(
              `/api/v1/admin/festive-category/${id}`,
              {
                visible,
              }
            );

            snackbar(response.data.message, "success");
            getAllCategory(page, limit, "", "");
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return (
          <Switch
            checked={params.row.visible ? true : false}
            onChange={(e) =>
              actionSwitchToggle(params.row._id, e.target.checked)
            }
          />
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      width: 120,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => handleClickPreview(params.row.image)}
          >
            <Box
              height={"50px"}
              // width={"50px"}
              component="img"
              src={`${imgUrl}/${params.row.image}`}
              alt=""
            />
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 120,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => deleteHandler(params.row._id)}
          >
            Delete
          </Button>
        );
      },
    },
    {
      field: "Edit",
      headerName: "Edit",
      width: 120,
      renderCell: (params) => {
        return (
          <Button variant="contained" onClick={() => handleEdit(params.row)}>
            Edit
          </Button>
        );
      },
    },
  ];

  const deleteHandler = async (id) => {
    try {
      setIsLoading(true);

      const res = await axios.delete(`/api/v1/admin/festive-category?id=${id}`);

      snackbar(res.data.message, "success");
      setIsLoading(false);
      getAllCategory(page, limit, "", "");
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAllCategory(page, limit, "", "");
  }, []);

  const getAllCategory = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/festive-category?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setCategory(res.data.category);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const createCategoryHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("image", image);

      const res = await axios.post(`/api/v1/admin/festive-category`, {
        ...data,
      });
      await axios.post(
        `/api/v1/admin/festive-category/${res.data.category._id}`,
        formData
      );
      snackbar(res.data.message, "success");
      setIsLoading(false);
      handleClose();
      getAllCategory(page, limit, "", "");
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };

  const editCategory = async (e) => {
    e.preventDefault();
    try {
      console.log("edit category", id);
      setIsLoading(true);
      let formData = new FormData();
      formData.append("image", image);

      const res = await axios.patch(
        `/api/v1/admin/edit_festive-_category/${id}`,
        {
          ...data,
        }
      );
      await axios.post(
        `/api/v1/admin/festive-category/${res.data.category._id}`,
        formData
      );
      snackbar(res.data.message, "success");
      setIsLoading(false);
      handleClose();
      getAllCategory(page, limit, "", "");
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllCategory(page, limit, "", "");
    if (item.length === 0) {
      getAllCategory(page, limit, "", "");
    } else {
      getAllCategory(page, limit, item[0].sort, item[0].field);
    }
  };
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    console.log(data);
  };
  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/categorys/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setCategory(res.data.category);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // onClick={createCategoryHandler}
  return (
    <Box>
      <Button onClick={handleOpen} variant="contained">
        Add festive category
      </Button>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={category}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllCategory(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllCategory(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
      <Modal
        open={createCategory}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid
              item
              xs={12}
              component="form"
              onSubmit={id ? editCategory : createCategoryHandler}
            >
              <Typography
                variant="h6"
                fontWeight={500}
                fontSize="16px"
                sx={{ color: "grey.main" }}
              >
                Title *
              </Typography>
              <TextField
                id="outlined-basic"
                fullWidth
                size="small"
                name="title"
                value={data.title}
                required
                variant="outlined"
                onChange={handleChange}
                sx={textFieldStyleForModal}
              />
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h6"
                  fontWeight={500}
                  fontSize="16px"
                  sx={{ color: "grey.main" }}
                >
                  Image*
                </Typography>
                <DragsAndDrop
                  heading="Upload Image"
                  inputName="Image"
                  aspect={1.6 / 1}
                  uploadFile={(x) => {
                    setImage(x);
                  }}
                />
              </Grid>

              <Grid container spacing={2} px={3} mt={3}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",

                    justifyContent: { xs: "center" },
                    alignItems: "end",
                  }}
                >
                  <Button variant="contained" type="submit">
                    {id ? "Update" : "Create"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <ImagePreview
        handleClosePreview={handleClosePreview}
        preview={preview}
        imageUrl={imageUrl}
      />
    </Box>
  );
}

export default FestiveCategory;
