import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";

import { AdminDataContext } from "./../../useContext/AdminContext";
function DeliveryBoysWIthCurrentOrder() {
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [data, setData] = useState([]);

  //   const [page, setPage] = useState(1);
  //   const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      field: "name",
      headerName: "Delivery Boy",
      width: 250,
      renderHeader: () => <strong>{"Delivery Boy"}</strong>,
      renderCell: (params) => params.row.deliveryPartner.name,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      renderHeader: () => <strong>{"Email"}</strong>,
      renderCell: (params) => params.row.deliveryPartner.email,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 130,
      renderHeader: () => <strong>{"Phone"}</strong>,
      renderCell: (params) => params.row.deliveryPartner.phone,
    },

    {
      field: "orderId",
      headerName: "orderId",
      width: 130,
      renderHeader: () => <strong>{"OrderId"}</strong>,
      renderCell: (params) => params.row.currentOrder.orderId,
    },
    {
      field: "status",
      headerName: "status",
      width: 130,
      renderHeader: () => <strong>{"Status"}</strong>,
      renderCell: (params) => params.row.currentOrder.status,
    },
    {
      field: "deliveryStatus",
      headerName: "deliveryStatus",
      width: 130,
      renderHeader: () => <strong>{"Delivery Status"}</strong>,
      renderCell: (params) => params.row.currentOrder.deliveryStatus,
    },

    {
      field: "available",
      headerName: "available",
      width: 100,
      renderHeader: () => <strong>{"Available"}</strong>,
    },
    {
      field: "online",
      headerName: "online",
      width: 100,
      renderHeader: () => <strong>{"Online"}</strong>,
    },
  ];

  useEffect(() => {
    freeDeliveryBoyButAvailableFalse();
  }, []);

  const freeDeliveryBoyButAvailableFalse = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/delivery/get-delivery-boy-current-order`
      );
      console.log("delivery", res.data.data);

      setData(res.data.data);
      //   setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography sx={{ fontWeight: 800, my: 1 }}>
        Delivery Boys with current order
      </Typography>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={data}
        getRowId={(row) => row._id}
        loading={loading}
        columns={columns}
      />
    </Box>
  );
}

export default DeliveryBoysWIthCurrentOrder;
