import {
  Button,
  Grid,
  Switch,
  TextField,
  styled,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import DragsAndDrop from "../../reusableComponent/imageUpload/DragsAndDrop";
import { imgUrl } from "../../url";
import { AdminDataContext } from "./../../useContext/AdminContext";
const StyledInput = styled(TextField)({
  width: "100%",
});
const FestiveBanner = () => {
  const [image, setImage] = useState("");
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  useEffect(() => {
    getAllCuisine(page, limit, "", "");
  }, []);

  const [title, setTitle] = useState("");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [menuItemsFile, setMenuItemsFile] = useState("");
  const [bannerId, setBannerId] = useState("");
  const uploadDocument = (e) => {
    setMenuItemsFile(e.target.files[0]);
  };

  const createCategoryHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let formData = new FormData();
      formData.append("image", image ? image : menuItemsFile);
      if ((image || menuItemsFile) && title) {
        const res = await axios.post(`/api/v1/admin/festival-banner/banner`, {
          bannerName: title,
        });
        await axios.patch(
          `/api/v1/admin/festival-banner/banner?festiveBannerId=${res.data.festiveBanner._id}`,
          formData
        );
        setImage("");
        setTitle("");
        snackbar(res.data.message, "success");
        setIsLoading(false);
        //   handleClose();
        getAllCuisine(page, limit, "", "");
      } else {
        setIsLoading(false);
        snackbar("Select image or enter title", "error");
      }
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };

  const handleEdit = (data) => {
    console.log("data", data);
    setTitle(data.bannerName);
    setBannerId(data._id);
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    try {
      // /api/v1/admin//promotion/banner-edit?promotionBannerId=6673d0bc1a80584893533c16
      setIsLoading(true);
      let formData = new FormData();
      formData.append("image", image ? image : menuItemsFile);
      if ((image || menuItemsFile) && title) {
        const res = await axios.patch(
          `/api/v1/admin/festival-banner/banner-edit?festiveBannerId=${bannerId}`,
          {
            bannerName: title,
          }
        );
        await axios.patch(
          `/api/v1/admin/festival-banner/banner?festiveBannerId=${bannerId}`,
          formData
        );
        setImage("");
        setTitle("");
        setBannerId("");
        snackbar(res.data.message, "success");
        setIsLoading(false);
        //   handleClose();
        getAllCuisine(page, limit, "", "");
      } else {
        setIsLoading(false);
        snackbar("Select image or enter title", "error");
      }

      console.log("Edit", title);
    } catch (error) {
      console.log("error edit", error);
    }
  };

  const handleDelete = async (data) => {
    try {
      console.log("banner delete", data._id);
      setIsLoading(true);
      const res = await axios.delete(
        `/api/v1/admin/festival-banner/banner?festiveBannerId=${data._id}`
      );
      getAllCuisine(page, limit, "", "");
      snackbar(res.data.message, "success");
      setIsLoading(false);
      console.log("deleted", res);
    } catch (error) {
      setIsLoading(false);
      console.log("error delete banner", error);
    }
  };

  const getAllCuisine = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/festival-banner/banner?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      setData(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "image",
      width: 100,
      headerName: "Image",
      renderCell: (params) => {
        return (
          <Box
            component="img"
            width="100%"
            src={`${imgUrl}/${params.row.bannerImage}`}
          ></Box>
        );
      },
    },
    {
      field: "bannerName",
      headerName: "Banner Name",
      width: 250,
    },
    {
      field: "visible",
      headerName: "Visible",
      width: 120,
      renderCell: (params) => {
        const actionSwitchToggle = async (id, visible) => {
          try {
            setLoading(true);
            const response = await axios.put(
              `/api/v1/admin/festival-banner/banner?festiveBannerId=${id}`,
              {
                visible,
              }
            );

            snackbar(response.data.message, "success");
            getAllCuisine(page, limit, "", "");
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return (
          <Switch
            checked={params.row.visible ? true : false}
            onChange={(e) =>
              actionSwitchToggle(params.row._id, e.target.checked)
            }
          />
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 80,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleEdit(params.row)}>Edit</Button>
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 80,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => handleDelete(params.row)}>Delete</Button>
          </>
        );
      },
    },
  ];

  const inputDocuments = {
    borderRadius: "10px",
    // background: "rgba(255, 255, 255, 0.31)",
    color: "rgba(255, 255, 255, 0.96)",
    border: " 1px solid rgba(103, 103, 103, 0.75)",
    "& fieldset": {
      border: "none",
      color: "rgba(255, 255, 255, 0.96);",
    },
  };

  return (
    <Box>
      <Grid container justifyContent={"center"}>
        <Grid item md={8} sm={12} sx={{ my: 2 }}>
          <Typography sx={{ color: "grey.main" }}>
            {" "}
            This is only for upload GIF
          </Typography>

          <StyledInput
            fullWidth
            variant="outlined"
            required
            inputProps={{ accept: "image/*,.pdf," }}
            type="file"
            size="small"
            // disabled={isAvailable ? true : false}
            onChange={(e) => uploadDocument(e)}
            sx={inputDocuments}
          />
          <Typography sx={{ color: "grey.main", textAlign: "center", my: 2 }}>
            {" "}
            OR
          </Typography>
        </Grid>
        <Grid item md={5} sm={12}>
          <DragsAndDrop
            heading="Upload Image"
            inputName="Image"
            aspect={2 / 1}
            uploadFile={(x) => {
              setImage(x);
            }}
          />
        </Grid>
        <Grid item sm={12} justifyContent={"center"}>
          <Box
            justifyContent={"center"}
            display="flex"
            marginTop={"30px"}
            width="100%"
          >
            <Grid item md={5} sm={12} justifyContent={"center"}>
              <Stack spacing={2}>
                <Typography sx={{ color: "grey.main" }}> Title</Typography>
                <TextField
                  onChange={(e) => setTitle(e.target.value)}
                  fullWidth
                  id="outlined-basic"
                  name="title"
                  value={title}
                  // label="Title"
                  variant="outlined"
                />
                {bannerId ? (
                  <Button variant="contained" onClick={handleSubmitEdit}>
                    Update{" "}
                  </Button>
                ) : (
                  <Button variant="contained" onClick={createCategoryHandler}>
                    Create{" "}
                  </Button>
                )}
                {/* <Button variant="contained" onClick={createCategoryHandler}>
                    Create{" "}
                  </Button> */}
              </Stack>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container marginTop={"30px"}>
        <DataGrid
          autoHeight
          rows={data}
          getRowId={(row) => row._id}
          rowCount={total}
          loading={loading}
          rowsPerPageOptions={[10, 30, 50, 70, 100]}
          pagination
          page={page - 1}
          pageSize={limit}
          paginationMode="server"
          // sortingMode="server"
          // onSortModelChange={handleSortModelChange}
          // filterMode="server"
          // onFilterModelChange={onFilterChange}
          onPageChange={(newPage) => {
            getAllCuisine(newPage + 1, limit, "", "");
            setPage(newPage + 1);
          }}
          onPageSizeChange={(newPageSize) => {
            getAllCuisine(page, newPageSize, "", "");
            setLimit(newPageSize);
            //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
          }}
          columns={columns}
        />
      </Grid>
    </Box>
  );
};

export default FestiveBanner;
