import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext } from "react";
import {
  paperBoxDashboardStyle,
  timeDurationDashboardStyle,
} from "../../cssStyling/Style";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AdminDataContext } from "./../../../useContext/AdminContext";
import axios from "axios";
const RestaurantReasonModal = (props) => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const matches = useMediaQuery("(min-width:1100px)");
  const matchesDown = useMediaQuery("(min-width:646px)");

  const onChange = (e) => {
    props.setReasonData({
      ...props.reasonData,
      [e.target.name]: e.target.value,
    });
  };
  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      console.log("data", props.reasonData);
      setIsLoading(true);
      const res = await axios.post(
        `/api/v1/admin/restaurant-response-about-ybites/${props.resId}`,
        props.reasonData
      );

      console.log("this is edit ", res);

      snackbar(res.data.message, "success");
      props.getAllRestaurant(1, 10, "", "");
      props.closeRestaurantReasonModal();
      setIsLoading(false);
    } catch (error) {
      console.log("Error while submitting or updating reason", error);
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: matches ? "30%" : matchesDown ? "60%" : "90%",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      {" "}
      <Modal
        open={props.restaurantReasonOpen}
        onClose={props.closeRestaurantReasonModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            spacing={2}
            component="form"
            onSubmit={submitHandler}
            // sx={{ overflow: "scroll", height: "95%" }}
          >
            <Box sx={{ ...paperBoxDashboardStyle, borderRadius: "10px" }}>
              <Typography sx={timeDurationDashboardStyle}>
                Restaurant Reason
              </Typography>
            </Box>

            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">Option</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                name="option"
                value={props.reasonData.option}
                label="Option"
                onChange={onChange}
              >
                {/* <MenuItem selected value="">
                  <em>None</em>
                </MenuItem> */}
                <MenuItem value={"Active"}>
                  <span
                    style={{
                      backgroundColor: "green",
                      //   color: "blue",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  >
                    Active
                  </span>
                </MenuItem>
                <MenuItem value={"Interested"}>
                  {" "}
                  <span
                    style={{
                      backgroundColor: "orange",
                      //   color: "blue",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  >
                    Interested
                  </span>
                </MenuItem>
                <MenuItem value={"Non-Interested"}>
                  {" "}
                  <span
                    style={{
                      backgroundColor: "red",
                      //   color: "blue",
                      padding: "5px",
                      borderRadius: "10px",
                    }}
                  >
                    Non-Interested
                  </span>{" "}
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              size="small"
              id="outlined-basic"
              sx={{ width: "100%" }}
              label="Message"
              onChange={onChange}
              name="message"
              value={props.reasonData.message}
              variant="outlined"
              multiline
              required
              rows={4}
            />

            <Button type="submit" variant="contained">
              Update
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default RestaurantReasonModal;
