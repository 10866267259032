import {
  Box,
  Button,
  TextField,
  Grid,
  Switch,
  Typography,
  Autocomplete,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { AdminDataContext } from "./../../useContext/AdminContext";
import { imgUrl } from "../../url";
import FilterByCity from "../../reusableComponent/filterByCity/FilterByCity";

function Coupon() {
  const { setIsLoading, snackbar, selectCity, filterType, setFilterType } =
    useContext(AdminDataContext);

  const [coupon, setCoupon] = useState([]);
  const [data, setData] = useState({ couponType: "admin" });
  const [couponType, setCouponType] = useState("admin");
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [createCoupon, setCreateCoupon] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [editCoupon, setEditCoupon] = useState("");

  // const [image, setImage] = useState("");
  const handleOpen = () => {
    setCreateCoupon(true);
    setData({ couponType: "admin" });
  };
  const handleClose = () => setCreateCoupon(false);

  const style = {
    height: "80vh",
    overflow: "scroll",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const filterOptions = ["All", "Admin", "Restaurant"];

  const columns = [
    {
      field: "title",
      headerName: "Title",
      width: 250,
    },
    {
      field: "code",
      headerName: "Code",
      width: 150,
    },
    {
      field: "description",
      headerName: "Description",
      width: 250,
    },
    {
      field: "maxDiscount",
      headerName: "Max Discount",
      width: 100,
    },
    {
      field: "minValue",
      headerName: "Min Value",
      width: 100,
    },
    {
      field: "percentage",
      headerName: "%",
      width: 50,
    },
    {
      field: "newCustomer",
      headerName: "New Customer",
      width: 120,
    },
    {
      field: "freeDelivery",
      headerName: "Free Delivery",
      width: 100,
    },
    {
      field: "restaurant",
      headerName: "Coupon Type",
      width: 200,
      renderCell: (params) => {
        return params.row.restaurant
          ? params.row.restaurant.brand_display_name
          : "Admin";
      },
    },
    {
      field: "expire",
      headerName: "Expire",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography>{`${new Date(params.row.expire).getDate()}-${
            new Date(params.row.expire).getMonth() + 1
          }-${new Date(params.row.expire).getFullYear()}`}</Typography>
        );
      },
    },
    {
      field: "total",
      headerName: "Total",
      width: 50,
      renderCell: (params) => {
        return params.row.freeDelivery
          ? params.row.freeDeliveryApplyCount
          : params.row.totalCount;
      },
    },
    {
      field: "visible",
      headerName: "Visible",
      width: 120,
      renderCell: (params) => {
        const actionSwitchToggle = async (id, visible) => {
          try {
            setLoading(true);
            const response = await axios.patch(
              `/api/v1/admin/coupon?couponId=${id}`,
              {
                visible,
              }
            );

            snackbar(response.data.message, "success");
            getAllCoupon(page, limit, "", "");
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return (
          <Switch
            checked={params.row.visible ? true : false}
            onChange={(e) =>
              actionSwitchToggle(params.row._id, e.target.checked)
            }
          />
        );
      },
    },
    {
      field: "image",
      headerName: "Image",
      width: 120,
      renderCell: (params) => {
        return (
          <Box
            height={"100%"}
            component="img"
            src={`${imgUrl}/${params.row.image}`}
            alt=""
          />
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 120,
      renderCell: (params) => {
        return <Button onClick={() => handleEdit(params.row)}>Edit</Button>;
      },
    },
  ];

  useEffect(() => {
    getAllCoupon(page, limit, "", "");
  }, [selectCity]);

  const getAllCoupon = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      setFilterType({ ...filterType, restaurant: true, city: false });
      console.log("selectCity", selectCity);
      const res = await axios.get(
        `/api/v1/admin/coupon?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}&couponType=${selectCity}`
      );
      setCoupon(res.data.data);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  console.log(couponType);
  const createCouponHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let res = {};
      if (editCoupon) {
        res = await axios.patch(`/api/v1/admin/editCoupon/${editCoupon}`, {
          ...data,
        });
        console.log("edit kro", data);
      } else {
        res = await axios.post(`/api/v1/admin/coupon`, {
          ...data,
        });
        console.log("create kro", data);
      }
      setData({ couponType: "admin" });
      snackbar(res.data.message, "success");
      setIsLoading(false);
      handleClose();
      getAllCoupon(page, limit, "", "");
    } catch (error) {
      console.log(error);
      snackbar(error.response.data.message, "error");

      setIsLoading(false);
    }
  };

  const handleEdit = (data) => {
    handleOpen();
    setEditCoupon(data._id);
    setData(data);
    console.log("ye data aa rha h ", data);
  };

  const handleSortModelChange = async (item) => {
    getAllCoupon(page, limit, "", "");
    if (item.length === 0) {
      getAllCoupon(page, limit, "", "");
    } else {
      getAllCoupon(page, limit, item[0].sort, item[0].field);
    }
  };
  const handleChange = (event) => {
    if (
      event.target.name === "freeDelivery" ||
      event.target.name === "newCustomer"
    ) {
      setData({ ...data, [event.target.name]: event.target.checked });
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
    console.log(data);
  };
  // console.log(data);
  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/coupons/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setCoupon(res.data.coupon);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getRestaurantData = async (e) => {
    try {
      const res = await axios.get(
        `/api/v1/admin/restaurants/search?query=${e.target.value}`
      );

      if (!(res.data.data.length === 0)) {
        setUserOptions(res.data.data);
      }
    } catch (error) {}
  };
  // onClick={createCouponHandler}
  console.log("restaurantssss", userOptions);
  return (
    <Box>
      <FilterByCity />

      <Button onClick={handleOpen} variant="contained">
        Add coupon
      </Button>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        autoHeight
        rows={coupon}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllCoupon(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllCoupon(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
      />
      <Modal
        open={createCoupon}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container>
            <Grid item sm={12} component="form" onSubmit={createCouponHandler}>
              <Stack spacing={2}>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  label="Title"
                  name="title"
                  value={data.title}
                  required
                  variant="outlined"
                  onChange={handleChange}
                />
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Coupon Type
                  </FormLabel>
                  <RadioGroup
                    row
                    value={couponType}
                    onChange={(e) => {
                      setCouponType(e.target.value);
                      setData({ ...data, couponType: e.target.value });
                    }}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="admin"
                      control={<Radio />}
                      label="Admin"
                    />

                    <FormControlLabel
                      value="restaurant"
                      control={<Radio />}
                      label="Restaurant"
                    />
                  </RadioGroup>
                </FormControl>
                {couponType === "restaurant" ? (
                  <Autocomplete
                    // multiple
                    aria-required
                    fullWidth
                    id="tags-outlined"
                    options={userOptions}
                    getOptionLabel={(userData) =>
                      `${userData.brand_display_name} Address : ${userData.business_contact}`
                    }
                    onInputChange={getRestaurantData}
                    onChange={(event, value) => {
                      console.log(value._id);
                      setData({ ...data, restaurant: value._id });
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Restaurant"
                        placeholder="Select Restaurant"
                      />
                    )}
                  />
                ) : (
                  <></>
                )}
                {/* code */}
                <TextField
                  id="outlined-basic"
                  fullWidth
                  label="Code"
                  name="code"
                  value={data.code}
                  required
                  variant="outlined"
                  onChange={handleChange}
                />
                {/* freeDelivery */}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={data.freeDelivery === true ? true : false}
                        //  defaultChecked={false}
                      />
                    }
                    label="Free Delivery"
                    name="freeDelivery"
                    value={data.freeDelivery}
                    onChange={handleChange}
                  />
                </FormGroup>
                {/* description */}
                <TextField
                  multiline
                  id="outlined-basic"
                  fullWidth
                  label="Description"
                  name="description"
                  value={data.description}
                  required
                  variant="outlined"
                  onChange={handleChange}
                />

                <TextField
                  id="outlined-basic"
                  fullWidth
                  type="date"
                  label="Expire"
                  name="expire"
                  value={data.expire}
                  required
                  variant="outlined"
                  onChange={handleChange}
                />
                {data.freeDelivery ? (
                  <TextField
                    id="outlined-basic"
                    fullWidth
                    type="number"
                    label="Free Delivery Apply Count"
                    name="freeDeliveryApplyCount"
                    value={data.freeDeliveryApplyCount}
                    required
                    variant="outlined"
                    onChange={handleChange}
                  />
                ) : (
                  <>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      type="number"
                      label="Percentage"
                      InputProps={{
                        inputProps: {
                          max: 100,
                          min: 0,
                        },
                      }}
                      name="percentage"
                      value={data.percentage}
                      required
                      variant="outlined"
                      onChange={handleChange}
                    />
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      type="number"
                      label="Total Count"
                      InputProps={{
                        inputProps: {
                          max: 1000000,
                          min: 0,
                        },
                      }}
                      name="totalCount"
                      value={data.totalCount}
                      required
                      variant="outlined"
                      onChange={handleChange}
                    />
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      type="number"
                      label="Apply Count"
                      name="applyCount"
                      value={data.applyCount}
                      InputProps={{
                        inputProps: {
                          max: 10,
                          min: 0,
                        },
                      }}
                      required
                      variant="outlined"
                      onChange={handleChange}
                    />
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      type="number"
                      label="Max Discount"
                      name="maxDiscount"
                      value={data.maxDiscount}
                      required
                      variant="outlined"
                      onChange={handleChange}
                    />
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      type="number"
                      label="Min Value"
                      name="minValue"
                      value={data.minValue}
                      required
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </>
                )}

                {/* newCustomer */}
                <FormGroup>
                  <FormControlLabel
                    control={<Switch defaultChecked={false} />}
                    label="New Customer"
                    name="newCustomer"
                    value={data.newCustomer}
                    onChange={handleChange}
                  />
                </FormGroup>

                <Button variant="contained" type="submit">
                  Create
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}

export default Coupon;
