import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import axios from "axios";
import React, { useState, useRef } from "react";
import FreeDeliveryBoyButAvailFalse from "./FreeDeliveryBoyButAvailFalse";
import DeliveryBoysWIthCurrentOrder from "./DeliveryBoysWIthCurrentOrder";

const DeliveryBoysAvailability = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  // ---------------------tab
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Delivery Boy Available false" {...a11yProps(0)} />
            <Tab label="Delivery Boys current order" {...a11yProps(1)} />
            {/* <Tab label="Delivery boy report" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <FreeDeliveryBoyButAvailFalse />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <DeliveryBoysWIthCurrentOrder />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default DeliveryBoysAvailability;
