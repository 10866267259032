import {
  Box,
  Button,
  Input,
  Modal,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AdminDataContext } from "./../../useContext/AdminContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import downloadPDF from "../DownloadPdf";
import { FileDownloadOutlined } from "@mui/icons-material";
import { capitalize } from "../../reusableComponent/capitalFirstCharacter";
import { jsPDF } from "jspdf";
import moment from "moment";
function DeliveryCash() {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);
  const [amount, setAmount] = useState([{ _id: "", amount: 0 }]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setLog([]);
  };
  const [complain, setComplain] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [log, setLog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cashLogPdf, setCashLogPdf] = useState({});

  const handleDownloadReportRemaining = async (deliveryPartnerId) => {
    try {
      const response = await axios.get(
        `/api/v1/admin/getCashLogRemaining/${deliveryPartnerId}`,
        {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
        }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "CashLog_report.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading cash log report:", error);
    }
  };

  const handleDownloadReport = async (deliveryPartnerId) => {
    try {
      const response = await axios.get(
        `/api/v1/admin/getCashLog/${deliveryPartnerId}`,
        {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
        }
      );

      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "delivery_Order_report.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading cash log report:", error);
    }
  };
  // renderCell: (params) => capitalize(params.row.name),

  const downloadPdf = (row) => {
    setCashLogPdf(row);
    let doc = new jsPDF("p", "pt", "a4");
    doc.html(document.querySelector("#downloadDocument"), {
      callback: function (pdf) {
        pdf.save("cashLogPdf.pdf");
      },
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      renderHeader: () => <strong>{"Name"}</strong>,
      width: 200,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.deliveryPartner
              ? capitalize(params.row.deliveryPartner.name)
              : ""}
          </Typography>
        );
      },
    },
    {
      field: "cashAmount",
      headerName: "Amount",
      width: 100,
      renderHeader: () => <strong>{"Amount"}</strong>,
      renderCell: (params) => {
        return parseFloat(params.row.cashAmount).toFixed(2);
      },
    },
    {
      field: "cashLimit",
      headerName: "Cash Limit",
      width: 120,
      renderHeader: () => <strong>{"Cash Limit"}</strong>,
    },

    {
      field: "Log",
      headerName: "Log",
      width: 100,
      renderHeader: () => <strong>{"Log"}</strong>,
      renderCell: (params) => {
        const onClickHandler = () => {
          handleOpen();
          setLog(params.row.deliveryPartnerCashLog);
        };
        return <Button onClick={onClickHandler}>View</Button>;
      },
    },

    {
      field: "Submitted",
      headerName: "Submitted",
      width: 200,
      renderHeader: () => <strong>{"Submitted"}</strong>,
      renderCell: (params) => {
        const amountValue = amount.find(
          (element) => element._id === params.row._id
        );
        const amountHandler = async (e) => {
          e.preventDefault();
          try {
            setLoading(true);
            const response = await axios.post(
              `/api/v1/admin/delivery/cash?deliveryPartnerCurrentLocationId=${params.row._id}`,
              { amount: amountValue.amount }
            );

            snackbar(response.data.message, "success");
            // getAllComplain(page, limit, "", "");
            setAmount([{ _id: "", amount: 0 }]);
            setLoading(false);
          } catch (error) {
            snackbar(error.response.data.message, "error");
            setLoading(false);
          }
        };

        return (
          <Box component={"form"} onSubmit={amountHandler}>
            <TextField
              required
              id="outlined-basic"
              //   label="amount"
              type="number"
              variant="outlined"
              size="small"
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              sx={{ width: "85px" }}
              value={amountValue ? amountValue.amount : 0}
              onChange={(e) =>
                setAmount([
                  {
                    _id: params.row._id,
                    amount:
                      e.target.value < params.row.cashAmount
                        ? e.target.value
                        : params.row.cashAmount,
                  },
                ])
              }
              // required
            />
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Box>
        );
      },
    },

    {
      field: "downloadPdfFile",
      headerName: "remaining",
      width: 200,
      renderHeader: () => <strong>{"Download Reports"}</strong>,
      renderCell: (params) => {
        return (
          <Button
            startIcon={<FileDownloadOutlined />}
            onClick={() => downloadPdf(params.row)}
          >
            Download
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    getAllComplain(page, limit, "", "");
  }, []);

  const downloadCashLog = async (id) => {
    try {
      const response = await axios.get(`/api/v1/admin/getCashLog/${id}`, {
        responseType: "text",
        headers: {
          Accept: "application/pdf",
        },
      });

      const pdfContent = response.data;

      // Extract the filename from the response headers
      const contentDisposition = response.headers["content-disposition"];
      const filenameMatch =
        contentDisposition && contentDisposition.match(/filename="(.+)"/);
      const filename = filenameMatch ? filenameMatch[1] : "document.pdf";

      const blob = new Blob([pdfContent], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
    // try {
    //   console.log("id is", id);
    //   setLoading(true);
    //   // const res = await axios.get(`/api/v1/admin/getCashLog/${id}`);

    //   // console.log(
    //   //   "res reportyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy",
    //   //   res
    //   // );

    //   const response = await axios.get(`/api/v1/admin/getCashLog/${id}`, {
    //     responseType: "text", // Set the responseType to 'text' since the content is a string
    //     headers: {
    //       Accept: "application/pdf",
    //     },
    //     method: "get",
    //   });
    //   console.log(
    //     "res reportyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy",
    //     response
    //   );
    //   const pdfContent = response.data;

    //   const blob = new Blob([pdfContent], { type: "application/pdf" });
    //   const url = window.URL.createObjectURL(blob);

    //   const link = document.createElement("a");
    //   link.href = url;
    //   link.download = "delivery_partner_cash_log_report.pdf"; // Set the fixed filename

    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);

    //   // downloadPDF(res.data);

    //   // setComplain(res.data.data);
    //   // setTotal(res.data.total);
    //   setLoading(false);
    // } catch (error) {
    //   console.log(error);
    //   setLoading(false);
    // }
  };

  const getAllComplain = async (pageNo, limitNo, sort, field) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/admin/delivery/cash?limit=${limitNo}&page=${pageNo}&field=${field}&sort=${sort}`
      );
      console.log("delivery res", res.data.data);
      let active = res.data.data.filter(
        (data) => data.deliveryPartner.status === "active"
      );
      console.log("delivery res active active", active);
      // setComplain(
      //   res.data.data.filter((data) => data.deliveryPartner.status === "active")
      // );
      setComplain(active);
      setTotal(active.length);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSortModelChange = async (item) => {
    getAllComplain(page, limit, "", "");
    if (item.length === 0) {
      getAllComplain(page, limit, "", "");
    } else {
      getAllComplain(page, limit, item[0].sort, item[0].field);
    }
  };

  const onFilterChange = async (e) => {
    console.log(e);
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/admin/get/complains/search`, {
        key: e.items[0].columnField,
        value: e.items[0].value,
      });
      setComplain(res.data.complain);
      setTotal(res.data.total);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const tableStyle = {
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "10px",
  };
  // onClick={createComplainHandler}
  return (
    <Box>
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        // autoHeight
        rows={complain}
        getRowId={(row) => row._id}
        rowCount={total}
        loading={loading}
        rowsPerPageOptions={[10, 30, 50, 70, 100]}
        pagination
        page={page - 1}
        pageSize={limit}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        filterMode="server"
        onFilterModelChange={onFilterChange}
        onPageChange={(newPage) => {
          getAllComplain(newPage + 1, limit, "", "");
          setPage(newPage + 1);
        }}
        onPageSizeChange={(newPageSize) => {
          getAllComplain(page, newPageSize, "", "");
          setLimit(newPageSize);
          //   setPageState((old) => ({ ...old, pageSize: newPageSize }));
        }}
        columns={columns}
        sx={{
          "& .bold-header": {
            fontWeight: "bold",
            color: "blue",
          },
          height: "600px",
          overflow: "auto",
        }}
      />
      <div id="downloadDocument" style={{ paddingLeft: "125px" }}>
        <div style={{ margin: 5 }}>
          <h3 style={{ fontWeight: "bold" }}>
            {" "}
            <u>Deliver Partner Report</u>{" "}
          </h3>
          <p>
            Deliver Partner -{" "}
            <span style={{ fontWeight: "bold" }}>
              {cashLogPdf?.deliveryPartner?.name}
            </span>
          </p>
          <p>
            Cash In Hand -{" "}
            <span style={{ fontWeight: "bold" }}>
              {parseFloat(cashLogPdf?.cashAmount).toFixed(2)}
            </span>
          </p>
          <p>
            Cash Limit -{" "}
            <span style={{ fontWeight: "bold" }}>
              {parseFloat(cashLogPdf?.cashLimit).toFixed(2)}
            </span>
          </p>
          <h4>Cash Log - </h4> <br />
          <table style={{ border: "1px solid black" }}>
            <tr
            // style={{
            //   borderBottom: "1px solid black",
            //   borderCollapse: "collapse",
            // }}
            >
              <th style={tableStyle}>S.No</th>

              <th style={tableStyle}>Deposit Amount</th>

              <th style={tableStyle}>Date And Time</th>
            </tr>
            {cashLogPdf?.deliveryPartnerCashLog?.map((row, index) => (
              <tr
                key={row._id}
                // style={{
                //   borderBottom: "1px solid black",
                //   borderCollapse: "collapse",
                // }}
              >
                <td style={tableStyle}>{index + 1}</td>

                <td style={tableStyle}>
                  {parseFloat(row?.amountSubmitted).toFixed(2)}
                </td>
                <td style={tableStyle}>
                  {moment(row.createdAt).format("DD/MM/YYYY hh:mm A")}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Amount</TableCell>
                  <TableCell align="center">Date And Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {log.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {index + 1}
                    </TableCell>

                    <TableCell align="center">
                      {parseFloat(row?.amountSubmitted).toFixed(2)}
                      {/* {parseFloat(row.amountSubmitted).toFixed(2)} */}
                    </TableCell>
                    <TableCell align="center">
                      {moment(row.createdAt).format("DD/MM/YYYY hh:mm A")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </Box>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default DeliveryCash;
