import React, { useEffect, useState } from "react";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddNewAddOnGroup from "./AddNewAddOnGroup";
import EditAddOnGroup from "./EditAddOnGroup";
import { Edit } from "@mui/icons-material";
import { AdminDataContext } from "./../../../useContext/AdminContext";
import { useContext } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const Addons = () => {
  const { setIsLoading, snackbar } = useContext(AdminDataContext);

  const [addOnsData, setAddOnsData] = useState([]);
  const [attributes, setAttributes] = useState();
  const [page, setPage] = useState(0);
  const [singleAddOn, setSingleAddOn] = useState({});
  const [attributesArray, setAttributesArray] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [restaurantName, setRestaurantName] = useState("");
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { restaurantId } = useParams();
  const getData = async () => {
    try {
      setIsLoading(true);

      const res = await axios.get(
        `/api/v1/admin/restaurant?restaurantId=${restaurantId}`
      );
      setAddOnsData(res.data.restaurant.addongroups);
      setRestaurantName(res.data.restaurant.brand_display_name);
      const attributesObj = {};
      for (
        let index = 0;
        index < res.data.restaurant.attributes.length;
        index++
      ) {
        const element = res.data.restaurant.attributes[index];
        attributesObj[element.attributeid] = element.attribute;
      }
      setAttributesArray(res.data.restaurant.attributes);
      setAttributes(attributesObj);
      snackbar(res.data.message, res.data.status);
      setIsLoading(false);
    } catch (error) {
      snackbar(error.response.data.message, error.response.data.status);
      setIsLoading(false);
    }
  };

  const handleEditAddOn = (item) => {
    setPage(2);
    setSingleAddOn(item);
    console.log(item);
  };

  const deleteAddonGroup = async (addongroupId) => {
    try {
      setIsLoading(true);

      const res = await axios.delete(
        `/api/v1/admin/addonGroup/${addongroupId}`
      );

      getData();
      setIsLoading(false);
    } catch (error) {
      snackbar(error.response.data.message, error.response.data.status);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      {page === 0 ? (
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center", b: 2 }}
        >
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 2,
              width: "100%",
            }}
          >
            <Typography
              sx={{ color: "#0C1844", fontWeight: "bold", mt: 2 }}
            >{`${restaurantName}`}</Typography>

            <Button variant="contained" onClick={() => setPage(1)}>
              Add Addons
            </Button>
          </Grid>
          {addOnsData.map((item, index) => (
            <Grid
              item
              xs={12}
              key={item._id}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Accordion
                expanded={expanded === `panel${index + 1}`}
                onChange={handleAccordionChange(`panel${index + 1}`)}
                sx={{
                  backgroundColor: "transparent",
                  border: "1px solid rgba(0, 0, 0, 0.34)",
                  borderRadius: "21px",
                  width: "100%",
                }}
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="white" />}
                  aria-controls={`panel${index + 1}a-content`}
                  id={`panel${index + 1}a-header`}
                  sx={{
                    background:
                      "linear-gradient(180deg, #FF9B25 0%, rgba(189, 50, 20, 0.76) 100%)",
                    boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.25)",
                    borderRadius: "2px",
                  }}
                >
                  {" "}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: "white.main",
                        fontSize: "18px",
                      }}
                    >
                      {item.addongroup_name}
                    </Typography>

                    <Box
                      sx={{
                        // maxWidth: "400px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="success"
                        endIcon={<Edit />}
                        onClick={() => handleEditAddOn(item)}
                        sx={{ marginRight: 10 }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="success"
                        endIcon={<DeleteIcon />}
                        onClick={() => deleteAddonGroup(item._id)}
                        sx={{ marginRight: 10 }}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2} mt={1}>
                    <Grid
                      item
                      xs={12}
                      // sm={6}
                      sx={{ display: "flex", justifyContent: "space-around" }}
                    >
                      <Box sx={{ minWidth: "100%" }}>
                        <TableContainer component={Paper}>
                          <Table
                            //   sx={{ minWidth: "100%" }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Price</TableCell>
                                <TableCell align="right">Attributes</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.addongroupitems.map((row) => (
                                <TableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.addonitem_name}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.addonitem_price}
                                  </TableCell>
                                  <TableCell align="right">
                                    {attributes[row.attributes]}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      ) : page === 1 ? (
        <AddNewAddOnGroup
          setPage={setPage}
          attributes={attributesArray}
          getData={getData}
          restaurantName={restaurantName}
        />
      ) : (
        <>
          <EditAddOnGroup
            setPage={setPage}
            attributes={attributesArray}
            singleAddOn={singleAddOn}
            getData={getData}
            restaurantName={restaurantName}
          />
        </>
      )}
    </div>
  );
};

export default Addons;
