import React, { useContext } from "react";
import LeftDrawer from "./LeftDrawer";
import { Route, Routes, useNavigate } from "react-router-dom";

import Dashboard from "../dashboard/Dashboard";
import Logout from "../logout";
import UserPermission from "../userPermission/UserPermission";

import { AdminDataContext } from "../../useContext/AdminContext";
import VerifiedRestaurant from "../restaurant/VerifiedRestaurant";
import UnVerifiedRestaurant from "../restaurant/UnVerifiedRestaurant";
import Category from "../Category";
import Cuisine from "../Cuisine";
import Owner from "../Owner";
import MenuItemUnverified from "./../menuItem/MenuItemUnverified";
import RestaurantMenuItem from "../menuItem/RestaurantMenuItem";
import Complain from "../complain/Complain";
import Coupon from "../coupon/Coupon";
import Delivery from "../delivery/Delivery";
import DeliveryUnverified from "../delivery/DeliveryUnverified";
import Customer from "../customer/Customer";
import Banner from "../banner/Banner";
import CustomerNotification from "../notification/CustomerNotification";
import RestaurantNotification from "../notification/RestaurantNotification";
import Brand from "../Brand";
import AppReview from "../appReview/AppReview";
import RestaurantTicket from "../restaurantTicket/RestaurantTicket";
import DeliveryTicket from "../deliveryTicket/DeliveryTicket";
import DeliveryNotification from "../notification/DeliveryNotification";
// import DeliveryMonitor from "../delivery/DeliveryMonitor";
import DeliveryCash from "../delivery/DeliveryCash";
import Report from "../report/Report";
import DeliveryBoyNotAvailable from "../../order/DeliveryBoyNotAvailable";
import AllOrders from "../../order/AllOrders";
import RestaurantNotAccepted from "../../order/RestaurantNotAccepted";
import RestaurantOrders from "../../order/RestaurantOrders";
import MenuItemUpload from "../menuItem/MenuItemUpload";
import ASM from "../asm/ASM";
import DeliveryReport from "../delivery/DeliveryReport";
import AllDeliveryReport from "../delivery/AllDeliveryReport";
import OnlineRestaurant from "../restaurant/OnlineRestaurant";
import PetPoojaRestaurant from "../restaurant/PetPoojaRestaurant";

import OwnerList from "../restaurant/petPoojaOnboarding/ownerList/OwnerList";
import RestaurantList from "../restaurant/petPoojaOnboarding/restaurantList/RestaurantList";
import CreateRestaurant from "../restaurant/petPoojaOnboarding/createRestaurantDetails/CreateRestaurant";
import SelectRestaurantMenuUpload from "../restaurant/petPoojaMenuItems/selectRestaurant/SelectRestaurantMenuUpload";
import MenuItems from "./../menuItem/itemCreate/MenuItems";
import Categories from "../restaurant/petPoojaMenuItems/categories/category/Category";
import Addons from "../menuItem/addons/Addons";

import Variants from "../restaurant/petPoojaMenuItems/variants/Variants";
import ItemsSelect from "../menuItem/ItemsSelect";
import CategoriesSelect from "../menuItem/CategoriesSelect";
import VariantsSelect from "../menuItem/VariantsSelect";
import AddonsSelect from "../menuItem/AddonsSelect";
import TaxesSelect from "../menuItem/TaxesSelect";
import UnVerifiedPetPooja from "../restaurant/UnVerifiedPetPooja";
import Version from "../Version";
import AsmMonitor from "../asm/AsmMonitor";
import ActiveCustomer from "../customer/ActiveCustomer";
import CustomerReward from "../customer/CustomerReward";
import CouponUsedOrders from "../../order/CouponUsedOrders";

import Calculations from "../allCalculation/Calculations";
import AllCalculation from "../allCalculation/AllCalculation";
import GstToBePaid from "../allCalculation/GstToBePaid";
import TdsToBePaid from "../allCalculation/TdsToBePaid";
import DeliveryToBePaid from "../allCalculation/DeliveryToBePaid";
import PaymentToRestaurant from "../allCalculation/PaymentToRestaurant";
import ProfitNLoss from "../allCalculation/ProfitNLoss";
import AsmMonitorByShivam from "../asm/AsmMonitorByShivam";
import AllCalculationPetPooja from "../allCalculation/petPoojaAllCalculation/AllCalculationPetPooja";
import CalculationsPetPooja from "../allCalculation/petPoojaAllCalculation/CalculationsPetPooja";
import DeliveryToBePaidPetPooja from "../allCalculation/petPoojaAllCalculation/DeliveryToBePaidPetPooja";
import GstToBePaidPetPooja from "../allCalculation/petPoojaAllCalculation/GstToBePaidPetPooja";
import PaymentToRestaurantPetPooja from "../allCalculation/petPoojaAllCalculation/PaymentToRestaurantPetPooja";
import ProfitNLossPetPooja from "../allCalculation/petPoojaAllCalculation/ProfitNLossPetPooja";
import TdsToBePaidPetPooja from "../allCalculation/petPoojaAllCalculation/TdsToBePaidPetPooja";
import ResLocationDeliveryBoy from "../../order/restaurantLocationWithDeliveryBoys/ResLocationDeliveryBoy";

import BhiwandiDeliveryBoy from "../delivery/bhiwandiDelivery/BhiwandiDeliveryBoy";
import BhiwandiUnverifiedDeliveryBoy from "../delivery/bhiwandiDelivery/BhiwandiUnverifiedDeliveryBoy";
import BhiwandiDeliveryPartnerLocation from "../delivery/bhiwandiDelivery/BhiwandiDeliveryPartnerLocation";
import DeliveryBoyLocationTrack from "../delivery/DeliveryBoyLocationTrack";
import BhiwandiRestaurant from "../restaurant/bhiwandiRestaurants/BhiwandiRestaurant";
import BhiwandiAllCalculation from "../allCalculation/bhiwandiCalculation/BhiwandiAllCalculation";
import ActiveRestaurants from "../restaurant/activeRestaurants/ActiveRestaurants";
import BlockedDelivery from "../delivery/BlockedDelivery";
import SearchRestaurant from "../restaurant/petPoojaOnboarding/ownerList/SearchByRestaurant";
import FestiveCategory from "../FestiveCategory";
import FestiveBanner from "../banner/FestiveBanner";
import DeliveryBoysAvailability from "../../order/deliveryBoysAvailability/DeliveryBoysAvailability";

// import Text from "../form/Test";

const ComponentRoute = () => {
  const { adminData } = useContext(AdminDataContext);

  return (
    <>
      <LeftDrawer>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          {/* ------shivam Code start---------- */}
          {adminData.permissions.restaurant ? (
            <>
              {/* <Route path="/restaurant/onboarding" element={<Onboarding />} /> */}
              <Route path="/restaurant/onboarding" element={<OwnerList />} />
            </>
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <>
              <Route
                path="/restaurant/restaurant-list/:id"
                element={<RestaurantList />}
              />

              <Route
                path="/restaurant/active-restaurant"
                element={<ActiveRestaurants />}
              />
            </>
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <>
              <Route
                path="/restaurant/create-restaurant-details/:id"
                element={<CreateRestaurant />}
              />
              <Route path="/restaurant/owner" element={<Owner />} />

              {/* <Route path="/restaurant/appReview" element={<Owner />} /> */}
            </>
          ) : (
            <></>
          )}

          {adminData.permissions.complain ? (
            <Route path="/restaurant/appReview" element={<AppReview />} />
          ) : (
            <></>
          )}

          {adminData.permissions.restaurant ? (
            <>
              <Route
                path="/restaurant/select-restaurant"
                element={<SelectRestaurantMenuUpload />}
              />
            </>
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <>
              {/* <Route path="/i/items" element={<ItemsSelect />}></Route> */}
              <Route path="/i/items" element={<ItemsSelect />}></Route>
              <Route
                path="/i/categories"
                element={<CategoriesSelect />}
              ></Route>
              <Route path="/i/variants" element={<VariantsSelect />}></Route>
              <Route path="/i/addons" element={<AddonsSelect />}></Route>
              <Route path="/i/taxes" element={<TaxesSelect />}></Route>
            </>
          ) : (
            <></>
          )}
          {adminData.permissions.version ? (
            <Route path="/version" element={<Version />}></Route>
          ) : (
            <></>
          )}

          {adminData.permissions.account ? (
            <>
              {/* <Route path="/i/items" element={<ItemsSelect />}></Route> */}

              <Route path="/accounts/" element={<Calculations />}>
                <Route path="" element={<AllCalculation />} />

                <Route
                  path="/accounts/bhiwandi-all-calculations/"
                  element={<BhiwandiAllCalculation />}
                ></Route>
                <Route path="gst-to-be-paid" element={<GstToBePaid />} />
                <Route path="tds-to-be-paid" element={<TdsToBePaid />} />
                <Route
                  path="payment-to-restaurant"
                  element={<PaymentToRestaurant />}
                />
                <Route path="profit-loss" element={<ProfitNLoss />} />

                <Route
                  path="delivery-charge-payable"
                  element={<DeliveryToBePaid />}
                />
              </Route>

              {/* ---------------------- pet pooja account */}

              <Route
                path="/accounts-petpooja/"
                element={<CalculationsPetPooja />}
              >
                <Route path="" element={<AllCalculationPetPooja />} />
                <Route
                  path="gst-to-be-paid-petpooja"
                  element={<GstToBePaidPetPooja />}
                />
                <Route
                  path="tds-to-be-paid-petpooja"
                  element={<TdsToBePaidPetPooja />}
                />
                <Route
                  path="payment-to-restaurant-petpooja"
                  element={<PaymentToRestaurantPetPooja />}
                />
                <Route
                  path="profit-loss-petpooja"
                  element={<ProfitNLossPetPooja />}
                />

                <Route
                  path="delivery-charge-payable-petpooja"
                  element={<DeliveryToBePaidPetPooja />}
                />
              </Route>
            </>
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <>
              <Route
                path="/i/items/:restaurantId"
                element={<MenuItems />}
              ></Route>
              <Route
                path="/i/categories/:restaurantId"
                element={<Categories />}
              ></Route>
              <Route
                path="/i/variants/:restaurantId"
                element={<Variants />}
              ></Route>
              <Route
                path="/i/addons/:restaurantId"
                element={<Addons />}
              ></Route>
              <Route
                path="/i/taxes/:restaurantId"
                element={<TaxesSelect />}
              ></Route>
            </>
          ) : (
            <></>
          )}
          {/* ------shivam Code end---------- */}
          {adminData.permissions.restaurant ? (
            <>
              <Route
                path="/restaurant/verified"
                element={<VerifiedRestaurant />}
              />
            </>
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <>
              <Route
                path="/restaurant/unverified"
                element={<UnVerifiedRestaurant />}
              />
            </>
          ) : (
            <></>
          )}

          {adminData.permissions.restaurant ? (
            <>
              <Route
                path="/restaurant/bhiwandi-restaurant"
                element={<BhiwandiRestaurant />}
              />
            </>
          ) : (
            <></>
          )}

          {adminData.permissions.restaurant ? (
            <Route
              path="/restaurant/pet_pooja"
              element={<PetPoojaRestaurant />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <Route
              path="/restaurant/unverified_pet_pooja"
              element={<UnVerifiedPetPooja />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <>
              <Route
                path="/order/deliveryBoyNotAvailable"
                element={<DeliveryBoyNotAvailable />}
              />
              <Route
                path="/order/nearest-delivery-boy"
                element={<ResLocationDeliveryBoy />}
              />
            </>
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <Route path="/order/allOrders" element={<AllOrders />} />
          ) : (
            <></>
          )}

          {adminData.permissions.restaurant ? (
            <Route
              path="/order/discountedOrders"
              element={<CouponUsedOrders />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <Route
              path="/order/restaurantNotAccepted"
              element={<RestaurantNotAccepted />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <Route
              path="/order/delivery-boy-availability"
              element={<DeliveryBoysAvailability />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <Route
              path="/order/restaurantOrder"
              element={<RestaurantOrders />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.restaurant ? (
            <Route path="/restaurant/online" element={<OnlineRestaurant />} />
          ) : (
            <></>
          )}
          {adminData.permissions.menu ? (
            <Route path="/menuUnverified" element={<MenuItemUnverified />} />
          ) : (
            <></>
          )}
          {adminData.permissions.menu ? (
            <Route path="/upload/menu" element={<MenuItemUpload />} />
          ) : (
            <></>
          )}
          {adminData.permissions.menu ? (
            <Route
              path="/menu/:restaurantId"
              element={<RestaurantMenuItem />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.category ? (
            <>
              <Route path="/category" element={<Category />} />
              <Route
                path="/category/festive-category"
                element={<FestiveCategory />}
              />
            </>
          ) : (
            <></>
          )}
          {adminData.permissions.category ? (
            <Route path="/report" element={<Report />} />
          ) : (
            <></>
          )}
          {adminData.permissions.category ? (
            <Route path="/brand" element={<Brand />} />
          ) : (
            <></>
          )}
          {adminData.permissions.complain ? (
            <Route path="/complain" element={<Complain />} />
          ) : (
            <></>
          )}
          {/* {adminData.permissions.complain ? (
            <Route path="/appReview/r" element={<AppReview />} />
          ) : (
            <></>
          )} */}

          {adminData.permissions.complain ? (
            <>
              <Route
                path="/ticket/ticket/r"
                element={<RestaurantTicket />}
              ></Route>
              <Route
                path="/ticket/ticket/d"
                element={<DeliveryTicket />}
              ></Route>
            </>
          ) : (
            <></>
          )}

          {adminData.permissions.complain ? (
            <Route path="/ticket/r" element={<RestaurantTicket />} />
          ) : (
            <></>
          )}
          {adminData.permissions.complain ? (
            <Route path="/ticket/d" element={<DeliveryTicket />} />
          ) : (
            <></>
          )}
          {adminData.permissions.coupon ? (
            <Route path="/coupon" element={<Coupon />} />
          ) : (
            <></>
          )}
          {adminData.permissions.customer ? (
            <>
              <Route path="/customer/allCustomers" element={<Customer />} />
              <Route
                path="/customer/activeCustomers"
                element={<ActiveCustomer />}
              />

              <Route path="/customer/reward" element={<CustomerReward />} />
            </>
          ) : (
            <></>
          )}

          {adminData.permissions.notification ? (
            <>
              <Route
                path="/notification/customerNotification"
                element={<CustomerNotification />}
              ></Route>
              <Route
                path="/notification/customerNotification"
                element={<CustomerNotification />}
              ></Route>
              <Route
                path="/notification/deliveryNotification"
                element={<DeliveryNotification />}
              ></Route>
              <Route
                path="/notification/restaurantNotification"
                element={<RestaurantNotification />}
              ></Route>
            </>
          ) : (
            <></>
          )}

          {adminData.permissions.notification ? (
            <Route
              path="/customerNotification"
              element={<CustomerNotification />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.notification ? (
            <Route
              path="/deliveryNotification"
              element={<DeliveryNotification />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.notification ? (
            <Route
              path="/restaurantNotification"
              element={<RestaurantNotification />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.banner ? (
            <>
              <Route path="/banner" element={<Banner />} />
              <Route
                path="/banner/festive-banner"
                element={<FestiveBanner />}
              />
            </>
          ) : (
            <></>
          )}

          {adminData.permissions.delivery ? (
            <>
              <Route
                path="/delivery/deliveryReport"
                element={<DeliveryReport />}
              ></Route>
              <Route path="/delivery/delivery" element={<Delivery />}></Route>
              <Route
                path="/delivery/blocked-delivery"
                element={<BlockedDelivery />}
              ></Route>
              <Route
                path="/delivery/allDeliveryReport"
                element={<AllDeliveryReport />}
              ></Route>
              {/* <Route
                path="/delivery/deliveryMonitor"
                element={<DeliveryMonitor />}
              ></Route> */}

              <Route
                path="/delivery/delivery-boy-location"
                element={<DeliveryBoyLocationTrack />}
              ></Route>

              <Route
                path="/delivery/deliveryCash"
                element={<DeliveryCash />}
              ></Route>
              <Route
                path="/delivery/deliveryUnverified"
                element={<DeliveryUnverified />}
              ></Route>
            </>
          ) : (
            <></>
          )}

          {adminData.permissions.delivery ? (
            <Route path="/deliveryReport" element={<DeliveryReport />} />
          ) : (
            <></>
          )}
          {adminData.permissions.delivery ? (
            <Route path="/allDeliveryReport" element={<AllDeliveryReport />} />
          ) : (
            <></>
          )}
          {/* {adminData.permissions.delivery ? (
            <Route path="/deliveryMonitor" element={<DeliveryMonitor />} />
          ) : (
            <></>
          )} */}
          {adminData.permissions.delivery ? (
            <Route path="/deliveryCash" element={<DeliveryCash />} />
          ) : (
            <></>
          )}
          {adminData.permissions.delivery ? (
            <Route
              path="/deliveryUnverified"
              element={<DeliveryUnverified />}
            />
          ) : (
            <></>
          )}
          {adminData.permissions.cuisine ? (
            <Route path="/cuisine" element={<Cuisine />} />
          ) : (
            <></>
          )}
          {/* {adminData.permissions.owner ? (
            <Route path="/owner" element={<Owner />} />
          ) : (
            <></>
          )} */}
          {adminData.permissions.owner ? (
            <Route path="/asm" element={<ASM />} />
          ) : (
            <></>
          )}
          {adminData.permissions.owner ? (
            <>
              <Route path="/a/monitor" element={<AsmMonitor />} />
              <Route path="/asm/asm-monitor" element={<AsmMonitorByShivam />} />
            </>
          ) : (
            <></>
          )}
          {adminData.permissions.permission ? (
            <>
              <Route path="/permission" element={<UserPermission />} />
              {/* <Route path="/version" element={<Version />}></Route> */}
            </>
          ) : (
            <></>
          )}
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </LeftDrawer>
    </>
  );
};

export default ComponentRoute;
