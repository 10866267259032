import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  addBtn,
  dashboardText,
  paperBoxDashboardStyle,
  textFieldBox,
  textHeadingInputFields,
  timeDurationDashboardStyle,
  valueBoxStyle,
} from "../cssStyling/Style";
const Dashboard = () => {
  const [data, setData] = useState();
  const [date, setDate] = useState("2023-05-25");
  const getData = async () => {
    try {
      console.log("date", date);

      const res = await axios.get(`/api/v1/admin/dashboard?date=${date}`);
      setData(res.data.data);
      console.log(res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: { xs: "center", md: "start" } }}
      >
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={8}>
              {" "}
              <Box
                sx={{
                  ...textFieldBox,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: { xs: "center", sm: "start" },
                }}
              >
                <Typography variant="body1" sx={textHeadingInputFields}>
                  Select Date
                </Typography>

                <TextField
                  size="small"
                  id="outlined-basic"
                  type="date"
                  variant="outlined"
                  name="startDate"
                  // onChange={onChangeDate}
                  onChange={(e) => setDate(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              {date ? (
                <>
                  <Box
                    sx={{
                      ...textFieldBox,
                      display: "flex",
                      // flexDirection: "column",
                      justifyContent: { xs: "center", sm: "start" },
                      mt: 4.6,
                    }}
                  >
                    <Button
                      sx={{
                        ...addBtn,
                        m: 0,
                        height: "40px",
                        width: "max-content",
                      }}
                      onClick={getData}
                      variant="contained"
                    >
                      Get Date
                    </Button>
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10} sm={5} md={3}>
          <Paper sx={{ borderRadius: "10px" }} elevation={3}>
            <Box sx={paperBoxDashboardStyle}>
              <Typography sx={timeDurationDashboardStyle}>Today</Typography>
            </Box>

            <Box sx={{ padding: "10px" }}>
              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Customer:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.todayCustomer}
                </Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}> DeliveryPartner:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.todayDeliveryPartner}
                </Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Order:</Typography>{" "}
                <Typography sx={dashboardText}>{data?.todayOrder}</Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Restaurant:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.todayRestaurant}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={10} sm={5} md={3}>
          <Paper sx={{ borderRadius: "10px" }} elevation={3}>
            <Box sx={paperBoxDashboardStyle}>
              <Typography sx={timeDurationDashboardStyle}>Week</Typography>
            </Box>

            <Box sx={{ padding: "10px" }}>
              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Customer:</Typography>{" "}
                <Typography sx={dashboardText}>{data?.weekCustomer}</Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}> DeliveryPartner:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.weekDeliveryPartner}
                </Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Order:</Typography>{" "}
                <Typography sx={dashboardText}>{data?.weekOrder}</Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Restaurant:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.weekRestaurant}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={10} sm={5} md={3}>
          <Paper sx={{ borderRadius: "10px" }} elevation={3}>
            <Box sx={paperBoxDashboardStyle}>
              <Typography sx={timeDurationDashboardStyle}>Month</Typography>
            </Box>

            <Box sx={{ padding: "10px" }}>
              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Customer:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.monthCustomer}
                </Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}> DeliveryPartner:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.monthDeliveryPartner}
                </Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Order:</Typography>{" "}
                <Typography sx={dashboardText}>{data?.monthOrder}</Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Restaurant:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.monthRestaurant}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={10} sm={5} md={3}>
          <Paper sx={{ borderRadius: "10px" }} elevation={3}>
            <Box sx={paperBoxDashboardStyle}>
              <Typography sx={timeDurationDashboardStyle}>Year</Typography>
            </Box>

            <Box sx={{ padding: "10px" }}>
              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Customer:</Typography>{" "}
                <Typography sx={dashboardText}>{data?.yearCustomer}</Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}> DeliveryPartner:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.yearDeliveryPartner}
                </Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Order:</Typography>{" "}
                <Typography sx={dashboardText}>{data?.yearOrder}</Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Restaurant:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.yearRestaurant}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={10} sm={5} md={3}>
          <Paper sx={{ borderRadius: "10px" }} elevation={3}>
            <Box sx={paperBoxDashboardStyle}>
              <Typography sx={timeDurationDashboardStyle}>
                Restaurant
              </Typography>
            </Box>

            <Box sx={{ padding: "10px" }}>
              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Live Restaurant:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.liveRestaurant}
                </Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>
                  {" "}
                  Inactive Restaurant:
                </Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.closeRestaurant}
                </Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>
                  Un-Approved Restaurant:
                </Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.unapprovedRestaurant}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        {/* // custom date */}
        <Grid item xs={10} sm={5} md={3}>
          <Paper sx={{ borderRadius: "10px" }} elevation={3}>
            <Box sx={paperBoxDashboardStyle}>
              <Typography sx={timeDurationDashboardStyle}>
                From {date}
              </Typography>
            </Box>

            <Box sx={{ padding: "10px" }}>
              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Customer:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.customCustomer}
                </Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}> DeliveryPartner:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.customDeliveryPartner}
                </Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Order:</Typography>{" "}
                <Typography sx={dashboardText}>{data?.customOrder}</Typography>
              </Box>

              <Box sx={valueBoxStyle}>
                <Typography sx={dashboardText}>Restaurant:</Typography>{" "}
                <Typography sx={dashboardText}>
                  {data?.customRestaurant}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
